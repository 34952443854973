@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  /* Brand Colors */
  --brand-teal-dark: #004443;
  --brand-teal-darker: #003635;
  --brand-teal-accent: #008080;
  --brand-green-accent: #87FFC0;  /* Adding the custom green */

  /* Background Gradients */
  --bg-gradient-start: #005F54;
  --bg-gradient-end: #00423A;

  /* White with Alpha */
  --white: rgba(255, 255, 255, 1);
  --white-3: rgba(255, 255, 255, 0.03);
  --white-5: rgba(255, 255, 255, 0.05);
  --white-10: rgba(255, 255, 255, 0.1);
  --white-15: rgba(255, 255, 255, 0.15);
  --white-20: rgba(255, 255, 255, 0.2);
  --white-30: rgba(255, 255, 255, 0.3);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-70: rgba(255, 255, 255, 0.7);
  --white-80: rgba(255, 255, 255, 0.8);
  --white-90: rgba(255, 255, 255, 0.9);

  /* Neutral Scale */
  --neutral-50: rgba(250, 250, 250, 1);
  --neutral-100: rgba(245, 245, 245, 1);
  --neutral-200: rgba(229, 229, 229, 1);
  --neutral-300: rgba(212, 212, 212, 1);
  --neutral-400: rgba(163, 163, 163, 1);
  --neutral-500: rgba(115, 115, 115, 1);
  --neutral-600: rgba(82, 82, 82, 1);
  --neutral-700: rgba(64, 64, 64, 1);
  --neutral-800: rgba(38, 38, 38, 1);
  --neutral-900: rgba(23, 23, 23, 1);
  --neutral-950: rgba(10, 10, 10, 1);

  /* Button States */
  --button-transparent-white: rgba(255, 255, 255, 0.01);

  /* Typography Scale - Extra Small */
  --text-xs-light: 0.75rem;
  --text-xs-regular: 0.75rem;
  --text-xs-medium: 0.75rem;
  --text-xs-semi-bold: 0.75rem;
  --text-xs-bold: 0.75rem;

  /* Typography Scale - Small */
  --text-sm-light: 0.88rem;
  --text-sm-regular: 0.88rem;
  --text-sm-medium: 0.88rem;
  --text-sm-semi-bold: 0.88rem;
  --text-sm-bold: 0.88rem;

  /* Typography Scale - Base */
  --text-base-light: 1rem;
  --text-base-regular: 1rem;
  --text-base-medium: 1rem;
  --text-base-semi-bold: 1rem;
  --text-base-bold: 1rem;

  /* Typography Scale - Large */
  --text-lg-light: 1.12rem;
  --text-lg-regular: 1.12rem;
  --text-lg-medium: 1.12rem;
  --text-lg-semi-bold: 1.12rem;
  --text-lg-bold: 1.12rem;

  /* Typography Scale - Extra Large */
  --text-xl-light: 1.25rem;
  --text-xl-regular: 1.25rem;
  --text-xl-medium: 1.25rem;
  --text-xl-semi-bold: 1.25rem;
  --text-xl-bold: 1.25rem;

  /* Typography Scale - 2XL to 9XL */
  --text-2xl-light: 1.5rem;
  --text-2xl-regular: 1.5rem;
  --text-2xl-medium: 1.5rem;
  --text-2xl-semi-bold: 1.5rem;
  --text-2xl-bold: 1.5rem;

  --text-3xl-light: 1.88rem;
  --text-3xl-regular: 1.88rem;
  --text-3xl-medium: 1.88rem;
  --text-3xl-semi-bold: 1.88rem;
  --text-3xl-bold: 1.88rem;

  --text-4xl-light: 2.25rem;
  --text-4xl-regular: 2.25rem;
  --text-4xl-medium: 2.25rem;
  --text-4xl-semi-bold: 2.25rem;
  --text-4xl-bold: 2.25rem;

  --text-5xl-light: 3rem;
  --text-5xl-regular: 3rem;
  --text-5xl-medium: 3rem;
  --text-5xl-semi-bold: 3rem;
  --text-5xl-bold: 3rem;

  --text-6xl-light: 3.75rem;
  --text-6xl-regular: 3.75rem;
  --text-6xl-medium: 3.75rem;
  --text-6xl-semi-bold: 3.75rem;
  --text-6xl-bold: 3.75rem;

  --text-7xl-light: 4.5rem;
  --text-7xl-regular: 4.5rem;
  --text-7xl-medium: 4.5rem;
  --text-7xl-semi-bold: 4.5rem;
  --text-7xl-bold: 4.5rem;

  --text-8xl-light: 6rem;
  --text-8xl-regular: 6rem;
  --text-8xl-medium: 6rem;
  --text-8xl-semi-bold: 6rem;
  --text-8xl-bold: 6rem;

  --text-9xl-light: 8rem;
  --text-9xl-regular: 8rem;
  --text-9xl-medium: 8rem;
  --text-9xl-semi-bold: 8rem;
  --text-9xl-bold: 8rem;

  /* Input Text Sizes */
  --input-small-medium: 0.88rem;
  --input-small-semi-bold: 0.88rem;
  --input-default-medium: 0.94rem;
  --input-medium-semi-bold: 0.94rem;
  --input-large-medium: 1.25rem;
  --input-large-semi-bold: 1.25rem;

  /* Badge and Keyboard Text Sizes */
  --badge-for-small-size: 0.62rem;
  --kbd-small: 0.75rem;
  --kbd-default: 1rem;
  --kbd-large: 1.12rem;
}

/* Base Styles */
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
}

body {
  background: radial-gradient(195.71% 126.49% at 13.26% 17.19%, var(--bg-gradient-start) 0%, var(--bg-gradient-end) 100%);
  color: var(--white);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.App {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

/* Navbar Styles */
.navbar {
  background-color: transparent;
  padding: 2.5rem 5rem 0.5rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-logo {
  height: 1.5rem;
  width: auto;
  margin-right: 0.5rem;
}

.version-tag {
  font-family: 'Inter';
  font-size: var(--text-xs-medium);
  font-style: normal;
  font-weight: 500;
  line-height: var(--text-base-regular);
  letter-spacing: 0.00375rem;
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: var(--white-10);
}

/* Location Navigation */
.location-dots {
  display: flex;
  gap: 0.25rem;
  margin-left: 0.5rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--white-20);
  cursor: pointer;
  transition: background-color 0.2s ease, opacity 0.2s ease;
}

.dot:hover {
  opacity: var(--white-80);
}

.dot.active {
  background-color: var(--brand-green-accent);
  opacity: 1;
}

/* Buttons */
.add-location-btn {
  background: none;
  border: none;
  color: var(--white);
  font-size: var(--text-xl-regular);
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.add-location-btn:hover {
  background-color: var(--white-10);
}

/* Search and Auth */
.nav-controls {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.search-container {
  position: relative;
  width: 18.75rem;
  z-index: 1;
  box-sizing: border-box;
}

.search-input {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--white-20);
  background: var(--white-3);
  backdrop-filter: blur(16px);
  color: var(--white);
  position: relative;
  box-sizing: border-box;
  font-size: var(--text-base-regular);
}

/* Remove the gradient border pseudo-element */
.search-container::before {
  display: none;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white-60);
}

.auth-buttons {
  display: flex;
  gap: 1rem;
}

.auth-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background: none;
  color: var(--white);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.auth-button:hover {
  background-color: var(--white-10);
}

.auth-button.sign-up {
  background-color: var(--brand-teal-accent);
}

.auth-button.sign-up:hover {
  background-color: var(--brand-teal-dark);
}

/* Main Content */
.main-content {
  max-width: none;
  position: relative;
  overflow: visible;
  flex: 1;
  width: calc(100vw - 8rem);
  box-sizing: border-box;
  z-index: 10;
  padding: 2.5rem 5rem;
}

.location-nav {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.location-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.location-header {
  margin-bottom: 2rem;
}

.location-title {
  font-size: var(--text-2xl-medium);
  font-weight: 500;
  color: var(--white);
  margin: 0;
}

.country-flag {
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
}

.country-flag img {
  border-radius: 2px;
  height: 16px;
  width: auto;
}

.country-flag.placeholder {
  font-size: 0.75rem;
  color: var(--white-60);
  background: var(--white-10);
  padding: 0.125rem 0.25rem;
  border-radius: 2px;
}

/* Tide Info Section */
.tide-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 0.5rem;
  padding: 2rem;
  background: var(--white-3);
  position: relative;
  user-select: text;
  isolation: isolate;
  transform: translate3d(0,0,0);
  transform-style: preserve-3d;
  z-index: 3;
  border: 0.0625rem solid var(--white-20);
  backdrop-filter: blur(0.5rem);
  -webkit-backdrop-filter: blur(2rem);
  overflow: hidden;
}

/* Remove these pseudo-elements */
.tide-info::before,
.tide-info::after {
  display: none;
}

.current-conditions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}

.condition-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.value-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Icons */
.icon-wrapper {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper svg {
  width: 100%;
  height: 100%;
  color: var(--brand-green-accent);
}

.icon-wrapper-small {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper-small svg {
  width: 100%;
  height: 100%;
  color: var(--brand-green-accent);
}

/* Typography */
.measurement-value {
  font-family: 'Inter';
  font-size: var(--text-5xl-bold);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.015rem;
  color: var(--white);
  user-select: text;
}

.measurement-label {
  font-family: 'Inter';
  font-size: var(--text-sm-regular);
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.004375rem;
  color: var(--white-60);
  user-select: text;
}

.section-heading {
  font-family: 'Inter';
  font-size: var(--text-xl-bold);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.00625rem;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0px;
  user-select: text;
}

.section-heading svg {
  color: var(--brand-green-accent);
}

/* Next Tides and Sun Times */
.info-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  padding: 1.5rem 2.5rem;
  border-top: 0.0625rem solid var(--white-10);
  margin: 0 -2rem;
}

.tide-detail-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.time-value {
  color: var(--white);
  font-family: 'Inter';
  font-size: var(--text-xl-light);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.00625rem;
  user-select: text;
}

/* Timestamp */
.timestamp {
  margin-top: 2rem;
  text-align: left;
}

.timestamp div {
  color: var(--white-60);
  font-size: var(--text-sm-regular);
}

.nav-button {
  background: none;
  border: none;
  color: var(--white);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: var(--white-60);
  transition: opacity 0.2s ease, background-color 0.2s ease;
}

.nav-button:hover {
  background-color: var(--white-10);
  opacity: 1;
}

.nav-button svg {
  width: 1.5rem;
  height: 1.5rem;
}



.location-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  will-change: transform;
  transform-style: preserve-3d;
  visibility: visible;
  height: auto;
  touch-action: none;
  isolation: isolate;
  z-index: 1;
  &:first-child {
    position: relative;
  }
}

.info-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-input::placeholder {
  color: var(--white-60);
}

/* Also add these to remove the default input focus styles */
.search-input:focus {
  outline: none;
}

.search-clear-button {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: var(--white);
  opacity: var(--white-60);
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-clear-button:hover {
  opacity: 1;
}

.wave-background {
  position: fixed;
  top: 17.5rem;
  left: 0;
  width: 100%;
  height: calc(100% - (2.5rem + 2rem + 2.5rem + 2rem + 2.5rem));
  z-index: -1;
  pointer-events: none;
  overflow: visible;
}

/* Adjust the wave height */
.wave-background svg {
  height: 100%;
  width: 100%;
}



.tide-info > *:not(.wave-background) {
  position: relative;
  z-index: 1;
  pointer-events: auto;
}

.dynamic-content {
  max-width: 64rem;
  margin: 0 0 2rem 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  touch-action: pan-y pinch-zoom;
  z-index: 1;
}

.locations-container {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 0;
  height: auto;
}

.tide-ruler {
  position: fixed;
  right: 0rem;
  top: 84px;
  height: calc(100vh - 84px);
  width: 8rem;
  display: flex;
  align-items: stretch;
  z-index: 5;
}

.ruler-lines {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.5rem 0;
  height: 100%;
  box-sizing: border-box;
}

.ruler-line {
  position: relative;
  height: 1px;
  background: var(--white-20);
  margin-left: auto;
}

.ruler-line.small {
  width: 1rem;
}

.ruler-line.half {
  width: 4rem;
}

.ruler-line.full {
  width: 8rem;
}

.tide-markers {
  position: absolute;
  left: auto;
  right: 0;
  top: 3.5rem;
  bottom: 3.5rem;
  overflow: visible;
}

.tide-marker {
  position: absolute;
  right: 0;
  padding-right: 1rem;
  width: max-content;
  min-width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transform: translateY(50%);
  white-space: nowrap;
}

.tide-marker:first-child {
  transform: translateY(0);
  padding-bottom: 1rem;   
}

.tide-marker:last-child {
  transform: translateY(100%);
  padding-top: 1rem;
}

.tide-marker.current {
  color: var(--brand-green-accent);
  padding-right: 0rem;
}

.tide-marker.current::before {
  display: none;
}

.tide-marker.high,
.tide-marker.low {
  color: var(--white);
}

.marker-time {
  font-size: var(--text-sm-regular);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.marker-time::after {
  content: "|";
  color: var(--white-60);
}

.marker-height {
  font-size: var(--text-sm-regular);
  font-weight: 500;
}

/* Add new current marker styles */
.current-marker-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.current-marker-container svg {
  width: 1rem;
  height: 1rem;
  color: var(--brand-green-accent);
}

.current-marker-line {
  width: 3rem;
  height: 1px;
  background-color: var(--brand-green-accent);
}

.tide-marker.current {
  color: var(--brand-green-accent);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  backdrop-filter: blur(8px);
  isolation: isolate;
}

.modal-content {
  background: var(--white-5);
  border-radius: 1rem;
  padding: 2rem;
  width: 90%;
  max-width: 32rem;
  position: relative;
  border: 1px solid var(--white-20);
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--white-60);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.modal-close:hover {
  background: var(--white-10);
  color: var(--white);
}

.location-search {
  margin: 2rem 0;
  width: 100%;
}

.search-input-container {
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
}

.search-input-container svg {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white-60);
}

.location-search-input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  background: var(--white-10);
  border: 1px solid var(--white-20);
  border-radius: 0.5rem;
  color: var(--white);
  font-size: var(--text-base-regular);
  box-sizing: border-box;
}

.location-search-dropdown {
  background: var(--white-5);
  border: 1px solid var(--white-20);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  max-height: 16rem;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.location-suggestion {
  padding: 1rem;
  cursor: pointer;
  color: var(--white);
  transition: all 0.2s ease;
}

.location-suggestion:hover {
  background: var(--white-10);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: var(--text-base-regular);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-button.primary {
  background: var(--brand-teal-accent);
  color: var(--white);
  border: none;
}

.modal-button.primary:hover {
  background: var(--brand-teal-dark);
}

.modal-button.secondary {
  background: var(--white-10);
  color: var(--white);
  border: 1px solid var(--white-20);
}

.modal-button.secondary:hover {
  background: var(--white-20);
}

.modal-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add remove location button styles */
.location-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove-location {
  background: none;
  border: none;
  color: var(--white-60);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.remove-location:hover {
  background: var(--white-10);
  color: var(--white);
}

/* Empty State Styles */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 84px - 5rem);  /* Viewport height minus navbar and padding */
  text-align: center;
  padding: 2rem;
  margin: 0 auto;  /* Center horizontally */
  max-width: 600px;  /* Set max width */
}

.empty-state h1 {
  font-size: var(--text-2xl-medium);
  color: var(--white);
  margin: 0 0 1rem 0;
}

.empty-state p {
  font-size: var(--text-base-regular);
  color: var(--white-60);
  margin: 0 0 2rem 0;
}

.empty-state .location-search {
  width: 100%;  /* Take full width of container */
}

.empty-state-content {
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.empty-state-content h2 {
  font-size: var(--text-2xl-medium);
  color: var(--white);
  margin: 0;
}

.empty-state-content p {
  font-size: var(--text-base-regular);
  color: var(--white-60);
  margin: 0;
}

.empty-state-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--brand-teal-accent);
  color: var(--white);
  border: none;
  border-radius: 0.5rem;
  font-size: var(--text-base-regular);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.empty-state-button:hover {
  background: var(--brand-teal-dark);
}

.empty-state-button svg {
  transition: transform 0.2s ease;
}

.empty-state-button:hover svg {
  transform: scale(1.1);
}

/* Add loading and error states */
.search-loading,
.search-error {
  padding: 0.5rem;
  text-align: center;
  color: var(--white-60);
}

.search-error {
  color: #ff6b6b;
}

/* Inline Location Search Styles */
.location-search-inline {
  width: 100%;
  max-width: 32rem;
  margin-top: 2rem;
}



.empty-state-search .location-search-input {
  background: transparent;
  border: none;
  font-size: var(--text-lg-regular);
}

.empty-state-search .location-search-dropdown {
  margin-top: 0.5rem;
  border: 1px solid var(--white-20);
  background: var(--white-5);
  border-radius: 0.5rem;
  max-height: 16rem;
  overflow-y: auto;
}

.empty-state-search .location-suggestion {
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.empty-state-search .location-suggestion:hover {
  background: var(--white-10);
}

.empty-state-search .search-loading,
.empty-state-search .search-error {
  text-align: center;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

/* Add new button styles */
.add-location-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--white-10);
  border: 1px solid var(--white-20);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-location-button:hover {
  background: var(--white-15);
  border-color: var(--white-30);
}

.add-location-button svg {
  stroke-width: 1.5;
}

.suggestion-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.suggestion-country {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--white-60);
  font-size: var(--text-sm-regular);
}

.suggestion-country .country-flag {
  margin-left: 0;
}

.empty-state .location-search-input:focus {
  padding: 0.75rem 1rem;  /* Remove any extra padding on focus */
  outline: none;
  border-color: var(--white-30);
  background: var(--white-5);
}

/* If needed, also update the base styles */
.empty-state .location-search-input {
  padding: 0.75rem 1rem;
  border: 1px solid var(--white-20);
  background: var(--white-3);
  border-radius: 0.5rem;
  color: var(--white);
  width: 100%;
}

/* Update input placeholder styles */
.location-search-input::placeholder {
  color: var(--white-40);
}

/* Also ensure the input text itself stays white */
.location-search-input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  background: var(--white-10);
  border: 1px solid var(--white-20);
  border-radius: 0.5rem;
  color: var(--white);
  font-size: var(--text-base-regular);
  box-sizing: border-box;
}

/* Add specific styles for empty state search input */
.empty-state .location-search-input::placeholder {
  color: var(--white-40);
}

/* Empty state search specific styles */
.empty-state .search-input-container {
  position: relative;
  width: 100%;
}

.empty-state .search-input-container svg {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white-60);
  z-index: 1;
}

.empty-state .location-search-input,
.empty-state .location-search-input:focus {  /* Apply to both normal and focus states */
  padding: 0.75rem 0.75rem 0.75rem 3rem;  /* Consistent padding */
  border: 1px solid var(--white-20);
  background: var(--white-3);
  border-radius: 0.5rem;
  color: var(--white);
  width: 100%;
  font-size: var(--text-base-regular);
  outline: none;
}

.empty-state .location-search-input:focus {
  border-color: var(--white-30);
  background: var(--white-5);
}
